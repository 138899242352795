<template>
  <v-card
    id="pricing-plan"
    class="text-center py-sm-15 py-5"
  >
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="6"
          sm="8"
          class="mx-auto"
        >
          <h1 class="font-weight-medium mb-5">
            Pricing Plans
          </h1>
          <p class="mb-3">
            All plans include 40+ advanced tools and features to boost your product. Choose the best plan to fit your needs.
          </p>

          <!--plan switch -->
          <div class="d-flex align-center justify-center mt-1 mb-1 pb-50">
            <span class="font-weight-semibold">Monthly</span>
            <v-switch
              v-model="annualPeriodShow"
              class="mx-3"
            ></v-switch>
            <span class="font-weight-semibold">Annually</span>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-row>
      <v-col
        v-for="(plan, index) in planList"
        :key="index"
        class="d-flex child-flex"
        cols="12"
        md="3"
        sm="6"
      >
        <div
          class="text-left custom-options-checkable"
        >
          <div class="">
            <input
              :id="plan.id"
              v-model="planSelected"
              class="custom-option-item-check"
              type="radio"
              :value="plan.id"
              @change="onChangePlan"
            >
            <label
              :for="plan.id"
              class="d-flex flex-column align-items-left px-2 py-2 custom-option-item"
            >
              <!-- chip -->
              <div
                v-show="plan.popular"
                style="position: absolute; right: 10px;"
                class="pricing-badge text-right"
              >
                <v-chip
                  small
                  color="primary"
                  class="v-chip-light-bg primary--text font-weight-semibold"
                >
                  Popular
                </v-chip>
              </div>

              <v-icon
                size="28"
                class="me-2 mt-1"
              >
                {{ plan.icon }}
              </v-icon>

              <span class="pt-1 pl-1">
                <h3>{{ plan.title }}</h3>
                <span class="d-block mt-75">
                  {{ plan.subtitle }}
                </span>
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                    <span class="pricing-value font-weight-bolder text-primary">{{ annualPeriodShow ? plan.yearlyPlan.perMonth : plan.monthlyPrice }}</span>
                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                  </div>
                  <small
                    v-show="annualPeriodShow"
                    class="annual-pricing text-muted"
                  >EUR {{ plan.yearlyPlan.totalAnual }} / year</small>
                </div>
              </span>
            </label>
          </div>

          <!-- plan button -->
          <div class="mt-2 mx-4">
            <v-btn
              outlined
              block
              color="success"
            >
              <router-link
                :to="{name:'auth-register', query:{ plan_code: plan.id}}"
                style="text-decoration: none;"
              >
                {{ plan.submitText }}
              </router-link>
            </v-btn>
          </div>
          <!--/Plan button -->

          <!-- Beneficts -->
          <div class="pb-2">
            <div
              v-for="(benefit, index2) in plan.planBenefits"
              :key="`${plan.id}-${index2}`"
              class="d-flex align-start text-sm px-5 my-4 cursor-pointer text-left"
            >
              <v-icon
                size="14"
                class="me-2 mt-1"
              >
                {{ icons.mdiCheckboxBlankCircleOutline }}
              </v-icon>
              <span class="text-sm text--secondary">{{ benefit }}</span>
            </div>
          </div>
          <!--/ Beneficts -->
        </div>
      </v-col>
    </v-row>

    <!-- pricing free trial -->
    <v-card-text class="pricing-free-trial my-16">
      <v-row>
        <v-col
          cols="12"
          md="10"
          class="mx-auto"
        >
          <div class="pricing-trial-content d-flex justify-space-between flex-column flex-md-row">
            <div class="text-center text-md-left mt-7">
              <p class="text-2xl font-weight-medium primary--text mb-2">
                Still not convinced? Start with a 14-day FREE trial!
              </p>
              <p class="text-base">
                You will get full access to with all the features for 14 days.
              </p>

              <v-btn
                color="primary"
                class="mt-4 mt-lg-6"
              >
                Start 14-day FREE trial
              </v-btn>
            </div>

            <!-- images -->
            <v-img
              contain
              height="278"
              max-width="250"
              src="@/assets/images/3d-characters/pose-fs-9.png"
              class="pricing-trial-img mx-auto"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <!--/ pricing free trial -->

    <v-card-text>
      <h2 class="text-2xl font-weight-medium mb-2">
        FAQ's
      </h2>
      <p>Let us help answer the most common questions.</p>
    </v-card-text>

    <v-card-text class="pricing-accordion mt-1">
      <v-row>
        <v-col
          cols="12"
          md="8"
          class="mx-auto text-left"
        >
          <v-expansion-panels v-model="pricingAccordion">
            <v-expansion-panel
              v-for="item in faqsList"
              :key="item.title"
            >
              <v-expansion-panel-header>
                {{ item.title }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.desc }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiCheckboxBlankCircleOutline,
  mdiBasketOutline, mdiShoppingOutline, mdiStorefrontOutline,
} from '@mdi/js'

export default {
  props: {
    planAnnual: {
      type: Boolean,
      default: true,
    },
    planCode: {
      type: String,
      default: 'purchaser-basic', // purchaser-basic, purchaser-big-market, seller-trader, seller-big-trader
    },
  },
  setup(props) {
    const pricingAccordion = ref(0)

    // *** PLAN *** //
    // purchaser-basic, purchaser-big-market, seller-trader, seller-big-trader
    const planSelected = ref(props.planCode)
    const planList = [
      {
        id: 'purchaser-basic',
        profileCode: 'purchaser',
        title: 'Comprador Básica',
        subtitle: 'Un comienzo simple para todos',
        // eslint-disable-next-line global-require
        img: require('@/assets/images/misc/pricing-tree-1.png'),
        icon: mdiBasketOutline,
        monthlyPrice: 0,
        yearlyPlan: {
          perMonth: 0,
          totalAnual: 0,
        },
        planBenefitsText: '',
        planBenefits: [
          'Marketplace',
          'Recibir ofertas sin filtros',
        ],
        popular: false,
        submitText: 'Get Started',
      },
      {
        id: 'purchaser-big-market',
        profileCode: 'purchaser',
        title: 'Comprador Big Market',
        subtitle: 'Un paso más en tu negocio',
        // eslint-disable-next-line global-require
        img: require('@/assets/images/misc/pricing-tree-2.png'),
        icon: mdiShoppingOutline,
        monthlyPrice: 54,
        yearlyPlan: {
          perMonth: 49,
          totalAnual: 588,
        },
        planBenefitsText: 'Todo lo del plan Básico, más',
        planBenefits: [
          'Lista de intereses',
          'Recibir ofertas de intereses',
          'Contactos',
          'Chat',
        ],
        popular: true,
        submitText: 'Try it free',
      },
      {
        id: 'seller-trader',
        profileCode: 'seller',
        title: 'Vendedor Trader',
        subtitle: 'Un comienzo simple para todos',
        // eslint-disable-next-line global-require
        img: require('@/assets/images/misc/pricing-tree-1.png'),
        icon: mdiStorefrontOutline,
        monthlyPrice: 32,
        yearlyPlan: {
          perMonth: 29,
          totalAnual: 348,
        },
        planBenefitsText: '',
        planBenefits: [
          'Espacio Web ',
          'Catálogo para 10 productos máximo',
          'Hacer ofertas (Con coste)',
          'Hasta 5 agentes comerciales incluidos',
          'Contactos',
          'Chat',
        ],
        popular: false,
        submitText: 'Try it free',
      },
      {
        id: 'seller-big-trader',
        profileCode: 'seller',
        title: 'Vendedor Big Trader',
        subtitle: 'Un paso más en tu negocio',
        // eslint-disable-next-line global-require
        img: require('@/assets/images/misc/pricing-tree-2.png'),
        icon: mdiStorefrontOutline,
        monthlyPrice: 54,
        yearlyPlan: {
          perMonth: 49,
          totalAnual: 588,
        },
        planBenefitsText: 'Todo lo del plan Trader, más',
        planBenefits: [
          'Catálogo ilimitado',
          'Hasta 5 ofertas gratuitas por mes y empresa',
          'Agentes comerciales ilimitados',
        ],
        popular: true,
        submitText: 'Try it free',
      },
    ]
    const planDataLocal = ref(null)
    // eslint-disable-next-line no-use-before-define
    // planDataLocal.value = readPlanData(planSelected.value)

    const onChangePlan = () => {
      // eslint-disable-next-line no-use-before-define
      planDataLocal.value = readPlanData(planSelected.value)
    }

    // Period switch (annual vs monthly)
    // const periodSwitchStatus = ref(props.planAnnual ? 'annual' : 'monthly')
    const annualPeriodShow = ref(props.planAnnual)

    // Se ha cambiado la frecuencia de pago
    const onTooglePeriod = () => {
      annualPeriodShow.value = !annualPeriodShow.value // Invert

      // Send data
      // eslint-disable-next-line no-use-before-define
      planDataLocal.value = readPlanData(planSelected.value)
    }

    // ***

    // Leemos toda la data del componente
    //  Otra estrategia sería mantener planDataLocal.value
    const readPlanData = planId => {
      const plan = planList.find(_p => _p.id === planId)

      // Send data
      const planConfigured = {
        profile_code: plan.profileCode,
        plan_code: planSelected.value,
        plan_price_montly: annualPeriodShow.value ? plan.monthlyPrice : plan.yearlyPlan.perMonth,
        plan_annual: !annualPeriodShow.value,
      }

      return planConfigured
    }

    const faqsList = [
      {
        title: 'General settings',
        desc: 'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
      },
      {
        title: 'Users',
        desc: 'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
      },
      {
        title: 'Personal data',
        desc: 'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
      },
      {
        title: 'Advanced settings',
        desc: 'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
      },
    ]

    return {
      // Plan
      planList,
      planSelected, // radio
      onChangePlan, // Change event

      // Plan pricing switch
      annualPeriodShow,
      onTooglePeriod,

      faqsList,
      pricingAccordion,
      icons: {
        mdiCheckboxBlankCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" >
@import '@core/preset/preset/mixins';
@import '@core/preset/preset/variables';

#pricing-plan {
  .popular {
    border-color: rgba(145, 85, 253, 0.3);
  }

  .pricing-free-trial {
    background-color: rgba(145, 85, 253, 0.04);
    position: relative;
    height: 14.625rem;
    .pricing-trial-img {
      position: relative;
      top: -3.5rem;
      right: 0;
    }
  }
  .pricing-accordion {
    .v-expansion-panels {
      .v-expansion-panel {
        box-shadow: none !important;
        margin-bottom: -1px;
        &::before {
          box-shadow: none;
        }
      }
    }
  }
}

@media (max-width: 960px) {
  #pricing-plan {
    .pricing-free-trial {
      height: auto;
      .pricing-trial-img {
        bottom: -1.2rem;
        top: unset;
      }
    }
  }
}

@include theme--child(pricing-accordion) using ($material) {
  .v-expansion-panels {
    .v-expansion-panel {
      border: 1px solid map-deep-get($material, 'dividers');
    }
  }
}
</style>

<style lang="scss">
  // @import '@core/scss/vue/pages/page-pricing.scss';
  .list-group .list-group-item {
      background: inherit;
  }
</style>
<style lang="scss" scoped>
  $primary: #0F3852; // Fishnet blue
  $accent: #C58712; // Fishnet gold

  .custom-option-item-check {
      clip: rect(0,0,0,0);
      position: absolute;
  }
  .custom-option-item-check:checked+.custom-option-item {
      // background-color: rgba(115,103,240,.12);
      background-color: rgba($primary, .12);
      border-color: $accent;
      color: $accent; //#7367f0;
  }
  /* .custom-options-checkable .custom-option-item { */
  .custom-options-checkable {
      /* background-color: rgba(130,134,139,.06); */
      background-color: rgba(255,255,255,1);
      border: 1px solid #ebe9f1;
      /* border-radius: 0.42rem; */
      color: #82868b;
      cursor: pointer;
      width: 100%;
  }

  .pricing-value {
      font-size: 4rem!important;
      color: $accent !important;
  }
</style>
